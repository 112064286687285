<template>
  <div class="DBChart">
    <div class="clearfix">
      <span class="DBChart__title">業界毎のSaaS導入割合</span>
      <b-select
        v-model="selected"
        class="float-end DBChart__dropdown text-truncate"
        :options="options"
        @change="selectedChange"
      >
      </b-select>
    </div>
    <div class="row DBChart__item">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <p class="font-size-24 mb_0">{{ totalCareer }}社</p>
        <div class="text-center text-muted">
          <div class="flex_row_wrap">
            <div v-for="item of chartNotes" :key="item.id" class="mr_8">
              <div class="mt-4">
                <p class="mb-0 text-truncate">
                  <em class="mdi mdi-circle me-1" :style="item.style"></em>
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 doughut-charts">
        <div class="doughut-chart">
          <v-chart :options="careerChart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import { dashboardService } from '@/services/data-service'
import { mapGetters } from 'vuex'

export default {
  name: 'SaasRateChart',
  components: { 'v-chart': ECharts },
  props: {
    optionChart: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      selected: 1,
      options: [],
      chartNotes: [],
      careerChart: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        color: [],
        series: [
          {
            name: '累計',
            type: 'pie',
            radius: ['50%', '80%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 10,
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      totalCareer: null,
      color: [],
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps', 'career']),
  },
  async created() {
    this.totalCareer = await dashboardService.getTotalCareer(this.selected)
    this.careerChart.series[0].data = await dashboardService.getCareerChart(this.selected)
    this.careerChart.series[0].data.forEach((item) => {
      this.handleDataSeries(item)
    })
    this.options = this.career.map((item) => ({ value: item.id, text: item.name }))
  },
  methods: {
    async selectedChange() {
      this.careerChart.color = []
      this.chartNotes = []
      this.totalCareer = await dashboardService.getTotalCareer(this.selected)
      this.careerChart.series[0].data = await dashboardService.getCareerChart(this.selected)
      this.careerChart.series[0].data.forEach((item) => {
        this.handleDataSeries(item)
      })
    },
    handleDataSeries(item) {
      const colors = this.apps.filter((color) => color.id === item.id).map((result) => result.color)
      if (colors) {
        this.careerChart.color.push(colors[0])
      }
      const note = this.apps
        .filter((color) => color.id === item.id)
        .map((res) => ({ id: res.id, name: res.name, style: 'color:' + res.color }))
      if (note) {
        this.chartNotes.push(note[0])
      }
    },
  },
}
</script>

<style scoped lang="scss">
.DBChart__dropdown {
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  height: 27px;
  max-width: 90px;
}
</style>
