<template>
  <div class="CheckboxChart" @click="handleClick">
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <rect :stroke="color" :fill="fillColor" x="3.5" y="3.5" width="13" height="13" rx="2" />
        <path
          v-if="dataChecked"
          stroke="#FFF"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 10.157L8.572 13 14 7"
        />
      </g>
    </svg>
    <span :style="{ color: color }">{{ legend }}</span>
  </div>
</template>
<script>
export default {
  name: 'CheckboxChart',
  props: {
    color: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    legend: {
      type: String,
      required: true,
    },
    checkAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataChecked: this.checked,
      hoverEvent: false,
    }
  },
  computed: {
    fillColor() {
      if (!this.dataChecked) {
        return '#FFF'
      } else {
        return this.color
      }
    },
  },
  watch: {
    checked(val) {
      this.dataChecked = val
    },
  },
  methods: {
    handleClick() {
      if (!this.checkAll) {
        this.toggleCheckbox()
        return
      }
      this.toggleCheckAll()
    },
    toggleCheckbox() {
      this.dataChecked = !this.dataChecked
      this.$emit('update:checked', this.dataChecked)
      this.$emit('handleClickCheckbox', {
        checked: this.dataChecked,
        label: this.legend,
      })
    },
    toggleCheckAll() {
      this.dataChecked = !this.dataChecked
      this.$emit('update:checked', this.dataChecked)
      this.$emit('handleCheckAll', this.dataChecked)
    },
  },
}
</script>
<style lang="scss">
.CheckboxChart {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 15px 15px 0 15px;
  flex-wrap: wrap;
}
</style>
