<template>
  <div class="card">
    <div class="card-body">
      <div class="float-end">
        <div class="input-group input-group-sm">
          <b-select
            v-model="selectedYear"
            class="DBFooter__dropdown-group"
            :options="years"
            @change="changeYear(selectedYear)"
          />
          <label class="input-group-text">年</label>
        </div>
      </div>
      <div class="float-end">
        <div class="input-group input-group-sm">
          <b-select
            v-model="selectedChart"
            class="DBFooter__dropdown"
            :options="selectChart"
            @change="changeDropdown(selectedChart)"
          />
        </div>
      </div>
      <div v-if="selectedChart === selectChart[1].value" class="float-end">
        <div class="input-group input-group-sm">
          <b-select
            v-model="selectedCareer"
            class="DBFooter__dropdown text-truncate"
            :options="optionsCareer"
          />
        </div>
      </div>
      <div class="float-end">
        <h4 v-if="selectedChart === selectChart[0].value" class="mb-4 text-total">
          年間累計額:{{ numberDetail }}円
        </h4>
        <h4 v-if="selectedChart === selectChart[1].value" class="mb-4 text-total">
          利用企業数：{{ numberDetail }}社
        </h4>
      </div>
      <h4 class="card-title mb-4">
        {{ title }}
      </h4>
      <stack-bar-chart
        v-if="flag && dataCreated"
        :series="series"
        :chart-options="chartOptions"
        :checked-label.sync="checkedLabel"
        :check-all.sync="checkAll"
      />
      <line-chart
        v-if="!flag && dataCreated"
        :series="seriesLineChart"
        :chart-options="optionLineChart"
      />
    </div>
  </div>
</template>

<script>
import StackBarChart from '@/pages/dashboard/stack-bar-chart'
import LineChart from '@/pages/dashboard-customer/line-chart'
import { dashboardService } from '@/services/data-service'
import { getYearsOptions } from '@/utils/date-utils'
import { mapGetters } from 'vuex'
/**
 * stackBarChart component
 */
export default {
  name: 'DashboardFooter',
  components: { LineChart, StackBarChart },
  props: {
    updating: {
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: new Date().getFullYear(),
      title: '業界毎の本サービスの利用売上',
      selectedChart: '1',
      selectChart: [
        { value: '1', text: '利用売上' },
        { value: '2', text: '企業数' },
      ],
      dataCreated: false,
      selectedYear: new Date().getFullYear(),
      numberDetail: '',
      flag: true,
      seriesLineChart: [],
      optionLineChart: dashboardService.getOptionLineChartDB(),
      series: [],
      chartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '15%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            '11月',
            '12月',
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
          ],
        },
        colors: [
          '#556EE6',
          '#F46A6A',
          '#F1B44C',
          '#50A5F1',
          '#34C38F',
          '#74788D',
          '#343A40',
          '#5DF25A',
        ],
        title: {
          text: '円',
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return Number(value)
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value) < 1 || value === Number.POSITIVE_INFINITY ? '' : value
            },
          },
        },
      },
      checkedLabel: {},
      checkAll: true,
      totalCost: 0,
      selectedCareer: '',
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['career']),
    years() {
      return getYearsOptions()
    },
    optionsCareer() {
      const result = []
      result.push({ value: '', text: '全て' })
      this.career.map((item) => {
        result.push({ value: item.id, text: item.name })
      })
      return result
    },
  },
  watch: {
    checkedLabel(newValue) {
      const x = this.series.filter((item) => item.name === newValue.label)[0].data
      const sum = x.reduce((a, b) => {
        return a + b
      })
      if (!newValue.checked) {
        this.numberDetail = this.numberDetail - sum
      }
      if (newValue.checked) {
        this.numberDetail = this.numberDetail + sum
      }
    },
    async checkAll(newValue) {
      if (newValue) {
        this.numberDetail = this.totalCost
      }
      if (!newValue) {
        this.numberDetail = 0
      }
    },
    selectedCareer() {
      this.changeDropdown(this.selectChart[1].value)
    },
  },
  async created() {
    const seriesStack = (await dashboardService.getStackChart('sales', this.selectedYear)).records
    this.series = seriesStack.map((item) => ({ name: item.name.name, data: item.data }))
    this.numberDetail = (await dashboardService.getStackChart('sales', this.selectedYear)).total
    this.totalCost = this.numberDetail
    this.dataCreated = true
  },
  methods: {
    async changeDropdown(data) {
      if (data === this.selectChart[0].value) {
        this.selectedCareer = '' // reset career
        this.series = []
        this.title = '業界毎の本サービスの利用売上'
        const seriesStack = (await dashboardService.getStackChart('sales', this.selectedYear))
          .records
        this.series = seriesStack.map((item) => ({ name: item.name.name, data: item.data }))
        this.numberDetail = (await dashboardService.getStackChart('sales', this.selectedYear)).total
        this.totalCost = this.numberDetail
        this.flag = true
      }
      if (data === this.selectChart[1].value) {
        this.seriesLineChart = []
        this.flag = false
        const result = await dashboardService.getStackChart(
          'company',
          this.selectedYear,
          this.selectedCareer
        )
        this.numberDetail = result.total
        this.title = '業界毎の利用企業数'
        const seriesLine = result.records.data
        this.seriesLineChart.push({ name: '利用企業数', data: seriesLine })
      }
    },
    async changeYear() {
      if (this.selectedChart === this.selectChart[0].value) {
        this.series = []
        const result = await dashboardService.getStackChart(
          'sales',
          this.selectedYear,
          this.selectedCareer
        )
        const seriesStack = result.records
        this.series = seriesStack.map((item) => ({ name: item.name.name, data: item.data }))
        this.numberDetail = result.total
        this.totalCost = this.numberDetail
      }
      if (this.selectedChart === this.selectChart[1].value) {
        this.seriesLineChart = []
        this.numberDetail = (
          await dashboardService.getStackChart('company', this.selectedYear)
        ).total
        this.title = '業界毎の利用企業数'
        const seriesLine = (await dashboardService.getStackChart('company', this.selectedYear))
          .records.data
        this.seriesLineChart.push({ name: '利用企業数', data: seriesLine })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.input-group-text {
  padding: 0 20px;
  width: 54px;
  text-align: center;
}
.mb-4 {
  margin-right: 80px;
  font-weight: 500;
}
.text-total {
  color: #50a5f1;
  font-size: 22.75px;
  font-weight: 500;
}
</style>
