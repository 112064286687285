<template>
  <div class="DBChart">
    <div class="clearfix">
      <span class="DBChart__title">SaaS毎の業界導入割合</span>
      <b-select
        v-model="selected"
        class="float-end DBFooter__dropdown text-truncate"
        :options="options"
        @change="selectedChange"
      >
      </b-select>
    </div>
    <div class="row DBChart__item">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <p class="font-size-24 mb_0">{{ totalSaas }}社</p>
        <div class="text-center text-muted">
          <div class="flex_row_wrap">
            <div v-for="item of chartNotes" :key="item.id" class="mr_8">
              <div class="mt-4">
                <p class="mb-0 text-truncate">
                  <em class="mdi mdi-circle me-1" :style="item.style"></em>
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 doughut-charts">
        <div class="doughut-chart">
          <v-chart :options="saasChart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import { dashboardService } from '@/services/data-service'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfessionRateChart',
  components: { 'v-chart': ECharts },
  props: {
    optionChart: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      selected: null,
      options: [],
      chartNotes: [],
      totalSaas: null,
      saasChart: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        color: [],
        series: [
          {
            name: '累計',
            type: 'pie',
            radius: ['50%', '80%'],
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 10,
                  fontWeight: 'bold',
                },
              },
            },
            data: [],
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
  },
  async created() {
    if (this.apps[0]) {
      this.selected = this.apps[0].id
      this.totalSaas = (await dashboardService.getSaasChart(this.selected)).total
      const chartOptions = (await dashboardService.getSaasChart(this.selected)).records
      this.saasChart.series[0].data = chartOptions.map((item) => ({
        name: item.career.name,
        value: item.value,
      }))
      this.saasChart.color = chartOptions.map((item) => item.career.color)
      this.chartNotes = chartOptions.map(this.handleDataChartNotes)
      this.options = this.apps.map((item) => ({ value: item.id, text: item.name }))
    }
  },
  methods: {
    async selectedChange() {
      this.saasChart.color = []
      this.chartNotes = []
      this.totalSaas = (await dashboardService.getSaasChart(this.selected)).total
      const chartOptions = (await dashboardService.getSaasChart(this.selected)).records
      this.saasChart.series[0].data = chartOptions.map((item) => ({
        name: item.career.name,
        value: item.value,
      }))
      this.saasChart.color = chartOptions.map((item) => item.career.color)
      this.chartNotes = chartOptions.map(this.handleDataChartNotes)
    },
    handleDataChartNotes(item) {
      return {
        id: item.id,
        name: item.career.name,
        style: 'color:' + item.career.color,
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
