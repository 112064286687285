<template>
  <Layout>
    <page-header title="ダッシュボード" />
    <div class="DashBoard">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between title-card">
                <span>主要SaaS</span>
                <router-link to="/management-service" class="text-hightlight"
                  >詳細を見る<em class="mdi mdi-chevron-right"
                /></router-link>
              </div>
              <div class="d-flex flex-wrap justify-content-around card-detail-saas">
                <div v-for="item in listApps" :key="item.id" class="brand_item">
                  <div class="card mini-stats-wid brand_item-content">
                    <div class="col-xl-6">
                      <div class="media">
                        <div class="me-3 brand_item-img">
                          <img :src="item.logo" alt="" />
                        </div>
                        <div class="media-body">
                          <h5 class="mb-1 brand_item-name">
                            {{ item.name }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-8">
          <div class="row">
            <div
              v-for="item of infors"
              :key="item.id"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4"
            >
              <div class="card mini-stats-wid">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body">
                      <p class="text-muted fw-medium mb-2">
                        {{ item.name }}
                      </p>
                      <h4 class="mb-0 text-hightlight text-hightlight__normal">
                        {{ item.count }}
                      </h4>
                    </div>
                    <div
                      class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary"
                    >
                      <span class="avatar-title" :style="item.style">
                        <img :src="item.src" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="is-loading">
            <div class="row">
              <div class="col-xl-6">
                <div class="card mini-stats-wid saas_chart">
                  <div class="card-body">
                    <profession-rate-chart />
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="card mini-stats-wid saas_chart">
                  <div class="card-body">
                    <saas-rate-chart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="col-lg-12">
                <DashboardFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main/app'

import 'echarts/lib/chart/pie'
import DashboardFooter from './dashboard-footer'
import PageHeader from '@/layouts/vertical/page-header'
import ProfessionRateChart from '@/pages/dashboard/profession-rate-chart'
import SaasRateChart from '@/pages/dashboard/saas-rate-chart'
import { dashboardService } from '@/services/data-service'
import { mapGetters } from 'vuex'
import router from '@/router'
export default {
  components: {
    SaasRateChart,
    ProfessionRateChart,
    PageHeader,
    DashboardFooter,
    Layout,
  },
  data() {
    return {
      infors: [
        {
          id: 1,
          name: '利用企業数',
          count: null,
          src: require('@/assets/icons/vector.svg'),
        },
        {
          id: 2,
          name: '１ヶ月以内の解約数',
          count: null,
          style: 'background-color: #e65555',
          src: require('@/assets/icons/fluent_people.svg'),
        },
        {
          id: 3,
          name: '連携中のサービス数',
          count: null,
          src: require('@/assets/icons/link.svg'),
        },
      ],
      listApps: [],
      flag: false,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
    ...mapGetters('user', ['isSystemUser', 'switchLoginEnable', 'isSwitched']),
    years() {
      const year = 2021
      return Array.from({ length: 4 }, (value, index) => year + index)
    },
  },
  async created() {
    if (!this.isSystemUser || (this.isSwitched && this.switchLoginEnable)) {
      await router.push({
        name: 'dashboard-customer',
      })
      return
    }
    Promise.all([
      dashboardService.getTotalUserActive(),
      dashboardService.getTotalUserInactive(),
      dashboardService.getTotalAppActive(),
      dashboardService.getListApps(),
    ]).then((res) => {
      this.infors[0].count = res[0]
      this.infors[1].count = res[1]
      this.infors[2].count = res[2]
      this.listApps = res[3]
    })
  },
}
</script>

<style lang="scss">
.DashBoard {
  :root {
    --height-brand: 65px;
  }
  .card-detail-saas {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media screen and (max-width: 1750px) {
    .row {
      --bs-gutter-x: 10px;
    }
    .card-detail-saas {
      overflow: auto;
    }
  }
  @media screen and (min-width: 1567px) and (max-width: 1710px) {
    .card-detail-saas {
      height: 318px;
      overflow: auto;
    }
    .saas_chart {
      height: 249px;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1566px) {
    .card-detail-saas {
      height: 339px;
      overflow: auto;
    }
    .saas_chart {
      height: 270px;
    }
  }
  @media screen and (min-width: 1711px) {
    .card-detail-saas {
      height: 300px;
      overflow: auto;
    }
  }

  .brand_item {
    width: 180px;
  }
  .media {
    height: 70px;
    align-items: center;
  }
  .brand_item-img {
    margin: auto;
    img {
      height: 50px;
      width: 50px;
    }
  }
  .mb12 {
    margin-bottom: -12px;
  }
  .brand_item-name {
    font-weight: 700;
  }
  .item-detail {
    color: #495057;
  }
  .title-card {
    a {
      font-size: 16px;
    }
    span {
      font-size: 16px;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .text-hightlight {
    color: #50a5f1;
    font-weight: 600;
    &__normal {
      font-weight: 500;
    }
  }
}
</style>
