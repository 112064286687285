<template>
  <div :style="{ height: '410px' }">
    <apexchart
      ref="apexchart"
      class="apex-charts"
      type="bar"
      dir="ltr"
      height="360"
      :series="series"
      :options="chartOptions"
    />
    <div class="legend">
      <check-box-legend
        v-for="data in legendData"
        :key="data.label"
        :color="data.color"
        :checked.sync="data.checked"
        :legend="data.label"
        :check-all="data.seriesName === ''"
        @handleClickCheckbox="handleClickCheckBox"
        @handleCheckAll="handleCheckAll"
      />
    </div>
  </div>
</template>

<script>
import CheckBoxLegend from '@/components/checkbox-legend'
export default {
  name: 'StackBarChart',
  components: { CheckBoxLegend },
  props: {
    series: { type: Array, default: () => [] },
    chartOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    checkedLabel: {
      type: Object,
      default: null,
    },
    checkAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      legendData: [
        { color: '#F178B6', checked: true, label: '全業界', seriesName: '' },
        { color: '#556EE6', checked: true, label: 'メーカー', seriesName: 'メーカー' },
        { color: '#F46A6A', checked: true, label: '小売', seriesName: '小売' },
        { color: '#F1B44C', checked: true, label: 'サービス', seriesName: 'サービス' },
        {
          color: '#50A5F1',
          checked: true,
          label: 'ソフトウェア・通信',
          seriesName: 'ソフトウェア・通信',
        },
        { color: '#34C38F', checked: true, label: '商社', seriesName: '商社' },
        {
          color: '#74788D',
          checked: true,
          label: '金融',
          seriesName: '金融',
        },
        { color: '#343A40', checked: true, label: 'マスコミ', seriesName: 'マスコミ' },
        {
          color: '#5DF25A',
          checked: true,
          label: '官公庁・公社・団体',
          seriesName: '官公庁・公社・団体',
        },
      ],
    }
  },
  methods: {
    handleClickCheckBox(data) {
      this.$emit('update:checkedLabel', data)
      if (data.checked) {
        this.$refs.apexchart.showSeries(data.label)
        this.checkIfAllChecked()
      } else {
        this.legendData.map((item) => {
          if (item.seriesName === '') {
            item.checked = false
          }
        })
        this.$refs.apexchart.hideSeries(data.label)
      }
    },
    handleCheckAll(isCheckAll) {
      this.$emit('update:checkAll', isCheckAll)
      if (!isCheckAll) {
        this.legendData.map((item) => {
          if (item.seriesName) {
            item.checked = false
            this.$refs.apexchart.hideSeries(item.seriesName)
          }
        })
      } else {
        this.legendData.map((item) => {
          if (item.seriesName) {
            item.checked = true
            this.$refs.apexchart.showSeries(item.seriesName)
          }
        })
      }
    },
    checkIfAllChecked() {
      let result = true
      this.legendData.map((item) => {
        if (item.seriesName) {
          result = item.checked && result
        }
      })
      if (result) {
        this.legendData.map((item) => {
          if (!item.seriesName) {
            item.checked = true
          }
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.legend {
  margin: 0 auto;
  display: flex;
}
</style>
